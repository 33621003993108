import React, { createContext, ReactNode, useContext, useState } from 'react';

interface VacancyTitleProviderProps {
    children: ReactNode;
    initialValue?: string;
}

export const VacancyTitleContext = createContext<string>(undefined);
export const SetVacancyTitleContext = createContext<React.Dispatch<React.SetStateAction<string>>>(undefined);

export const VacancyTitleProvider: React.FC<VacancyTitleProviderProps> = ({
    children,
    initialValue,
}: VacancyTitleProviderProps) => {
    const [state, setState] = useState<string>(initialValue);

    return (
        <VacancyTitleContext.Provider value={state}>
            <SetVacancyTitleContext.Provider value={setState}>{children}</SetVacancyTitleContext.Provider>
        </VacancyTitleContext.Provider>
    );
};

export const useVacancyTitle = () => {
    const context = useContext(VacancyTitleContext);
    if (context === undefined) {
        throw new Error('useVacancyTitle must be used within a VacancyTitleProvider');
    }
    return context;
};

export const useSetVacancyTitle = () => {
    const context = useContext(SetVacancyTitleContext);
    if (context === undefined) {
        throw new Error('useSetVacancyTitle must be used within a VacancyTitleProvider');
    }
    return context;
};
