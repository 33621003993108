import React, { createContext, ReactNode, useContext } from 'react';
import useChatbot, { UseChatbotOutput } from '../hooks/useChatbot';

const ChatbotContext = createContext<UseChatbotOutput | undefined>(undefined);

export const ChatbotProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const chatbot = useChatbot();
    return <ChatbotContext.Provider value={chatbot}>{children}</ChatbotContext.Provider>;
};

export const useChatbotContext = () => {
    const context = useContext(ChatbotContext);
    if (!context) {
        throw new Error('useChatbotContext must be used within a ChatbotProvider');
    }
    return context;
};
