import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { logger } from './utils/logger';
import { realtimeUserMonitoringService } from './services/realtimeUserMonitoring.service';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

if (process.env.REACT_APP_ENABLE_AUTOMATED_ACCESSIBILITY_CHECKS === 'true' && process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-extraneous-dependencies
    const axe = require('react-axe');
    axe(React, ReactDOM, 1000);
}

if (process.env.REACT_APP_MSW === 'true' && process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
    const { worker } = require('./mocks/browser');
    worker.start();
}

logger.init({
    logLevel: process.env.REACT_APP_LOGGER_LEVEL,
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    applicationId: process.env.REACT_APP_DATADOG_RUM_CLIENT_APP_ID,
});

if (process.env.REACT_APP_FEATURE_ENABLE_DATADOG_RUM === 'true') {
    realtimeUserMonitoringService.init({
        clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
        applicationId: process.env.REACT_APP_DATADOG_RUM_CLIENT_APP_ID,
    });
}

ReactDOM.render(<App />, document.getElementById('root'));
