"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SLAQuestionDifficulty = exports.SpontaneousSpeechTypes = exports.SLAVariants = exports.SLAAssessmentLocale = exports.SLASections = exports.SLALength = exports.SpokenLanguageAssessmentStepCategory = void 0;
// eslint-disable-next-line no-shadow
var SpokenLanguageAssessmentStepCategory;
(function (SpokenLanguageAssessmentStepCategory) {
    SpokenLanguageAssessmentStepCategory["fluency"] = "fluency";
    SpokenLanguageAssessmentStepCategory["pronunciation"] = "pronunciation";
    SpokenLanguageAssessmentStepCategory["listening"] = "listening";
    SpokenLanguageAssessmentStepCategory["spontaneous"] = "spontaneous";
})(SpokenLanguageAssessmentStepCategory = exports.SpokenLanguageAssessmentStepCategory || (exports.SpokenLanguageAssessmentStepCategory = {}));
// eslint-disable-next-line no-shadow
var SLALength;
(function (SLALength) {
    SLALength["short"] = "short";
    SLALength["long"] = "long";
})(SLALength = exports.SLALength || (exports.SLALength = {}));
// eslint-disable-next-line no-shadow
var SLASections;
(function (SLASections) {
    SLASections["spontaneousSpeech"] = "spontaneousSpeech";
    SLASections["scriptedSpeech"] = "scriptedSpeech";
})(SLASections = exports.SLASections || (exports.SLASections = {}));
// eslint-disable-next-line no-shadow
var SLAAssessmentLocale;
(function (SLAAssessmentLocale) {
    SLAAssessmentLocale["en-US"] = "en-US";
    SLAAssessmentLocale["fr-FR"] = "fr-FR";
    SLAAssessmentLocale["fr-CA"] = "fr-CA";
})(SLAAssessmentLocale = exports.SLAAssessmentLocale || (exports.SLAAssessmentLocale = {}));
// eslint-disable-next-line no-shadow
var SLAVariants;
(function (SLAVariants) {
    SLAVariants["Default"] = "default";
    SLAVariants["LATAMShort"] = "LATAMShort";
    SLAVariants["IndiaShort"] = "IndiaShort";
})(SLAVariants = exports.SLAVariants || (exports.SLAVariants = {}));
// eslint-disable-next-line no-shadow
var SpontaneousSpeechTypes;
(function (SpontaneousSpeechTypes) {
    SpontaneousSpeechTypes["WorkRelated"] = "workRelated";
    SpontaneousSpeechTypes["Unrelated"] = "unrelated";
})(SpontaneousSpeechTypes = exports.SpontaneousSpeechTypes || (exports.SpontaneousSpeechTypes = {}));
// eslint-disable-next-line no-shadow
var SLAQuestionDifficulty;
(function (SLAQuestionDifficulty) {
    SLAQuestionDifficulty["band2000"] = "band2000";
    SLAQuestionDifficulty["A1"] = "A1";
    SLAQuestionDifficulty["A2"] = "A2";
    SLAQuestionDifficulty["band3000"] = "band3000";
    SLAQuestionDifficulty["B1"] = "B1";
    SLAQuestionDifficulty["B2"] = "B2";
    SLAQuestionDifficulty["band4000"] = "band4000";
    SLAQuestionDifficulty["C1"] = "C1";
    SLAQuestionDifficulty["C2"] = "C2";
})(SLAQuestionDifficulty = exports.SLAQuestionDifficulty || (exports.SLAQuestionDifficulty = {}));
