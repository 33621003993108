"use strict";
/* eslint no-shadow: 0 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.LanguageTestLocale = exports.LanguageTestModuleQuestionDifficulty = exports.LanguageTestModuleQuestionType = void 0;
var LanguageTestModuleQuestionType;
(function (LanguageTestModuleQuestionType) {
    LanguageTestModuleQuestionType["comprehension"] = "comprehension";
    LanguageTestModuleQuestionType["vocabulary"] = "vocabulary";
    LanguageTestModuleQuestionType["grammar"] = "grammar";
})(LanguageTestModuleQuestionType = exports.LanguageTestModuleQuestionType || (exports.LanguageTestModuleQuestionType = {}));
var LanguageTestModuleQuestionDifficulty;
(function (LanguageTestModuleQuestionDifficulty) {
    LanguageTestModuleQuestionDifficulty["B2C1"] = "B2C1";
    LanguageTestModuleQuestionDifficulty["B2"] = "B2";
})(LanguageTestModuleQuestionDifficulty = exports.LanguageTestModuleQuestionDifficulty || (exports.LanguageTestModuleQuestionDifficulty = {}));
var LanguageTestLocale;
(function (LanguageTestLocale) {
    LanguageTestLocale["nl-NL"] = "nl-NL";
    LanguageTestLocale["zh-CN"] = "zh-CN";
    LanguageTestLocale["da-DK"] = "da-DK";
    LanguageTestLocale["fr-FR"] = "fr-FR";
    LanguageTestLocale["de-DE"] = "de-DE";
    LanguageTestLocale["hu-HU"] = "hu-HU";
    LanguageTestLocale["it-IT"] = "it-IT";
    LanguageTestLocale["ja-JP"] = "ja-JP";
    LanguageTestLocale["ko-KR"] = "ko-KR";
    LanguageTestLocale["nb-NO"] = "nb-NO";
    LanguageTestLocale["pt-PT"] = "pt-PT";
    LanguageTestLocale["ru-RU"] = "ru-RU";
    LanguageTestLocale["es-ES"] = "es-ES";
    LanguageTestLocale["sv-SE"] = "sv-SE";
    LanguageTestLocale["pl-PL"] = "pl-PL";
    LanguageTestLocale["bg-BG"] = "bg-BG";
    LanguageTestLocale["fi-FI"] = "fi-FI";
    LanguageTestLocale["zh-HK"] = "zh-HK";
    LanguageTestLocale["hr-HR"] = "hr-HR";
    LanguageTestLocale["cs-CS"] = "cs-CS";
    LanguageTestLocale["el-GR"] = "el-GR";
    LanguageTestLocale["et-EE"] = "et-EE";
    LanguageTestLocale["is-IS"] = "is-IS";
    LanguageTestLocale["lt-LT"] = "lt-LT";
    LanguageTestLocale["lv-LV"] = "lv-LV";
    LanguageTestLocale["mt-MT"] = "mt-MT";
    LanguageTestLocale["ro-RO"] = "ro-RO";
    LanguageTestLocale["sk-SK"] = "sk-SK";
    LanguageTestLocale["sl-SL"] = "sl-SL";
    LanguageTestLocale["es-419"] = "es-419";
    LanguageTestLocale["en-US"] = "en-US";
    LanguageTestLocale["uk-UK"] = "uk-UK";
    LanguageTestLocale["vi-VI"] = "vi-VI";
    LanguageTestLocale["id-ID"] = "id-ID";
    LanguageTestLocale["tr-TR"] = "tr-TR";
    LanguageTestLocale["ar-AR"] = "ar-AR";
    LanguageTestLocale["th-TH"] = "th-TH";
    LanguageTestLocale["ur-UR"] = "ur-UR";
    LanguageTestLocale["pt-BR"] = "pt-BR";
    LanguageTestLocale["he-HE"] = "he-HE";
    LanguageTestLocale["ca-CA"] = "ca-CA";
    LanguageTestLocale["fr-CA"] = "fr-CA";
    LanguageTestLocale["ms-MS"] = "ms-MS";
    LanguageTestLocale["en-GB"] = "en-GB";
    LanguageTestLocale["hi-HI"] = "hi-HI";
})(LanguageTestLocale = exports.LanguageTestLocale || (exports.LanguageTestLocale = {}));
