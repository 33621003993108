export interface WebchatKeyState {
    webchatKey: string;
    isNewSession: boolean;
}

export interface ChatbotTransferData {
    data: TransferDataObject;
    webchatKey: string;
    openWebchat?: boolean;
}

export interface InitChatbotTransferData {
    id: string;
    botxoUnbranded: number;
    cvars: ChatbotData;
    mode: string;
    noDelayMode?: boolean;
    webchatKey: string;
    autoloadDelay?: number;
    debuggerMode?: number;
    ref?: number;
}

interface TransferDataObject {
    type: string;
    cvars: ChatbotData;
    mod_id?: number;
    reset: boolean;
}

// TODO: This is not a finalized data structure
export type ChatbotData = {
    URL: string;
    pageUrl: string;
    currentPage: string;
    harverAccountId?: string; // TODO: Get accountId and candidateId for chatbot with getJourneyMetadata? How do we get account name?
    harverAccountName?: string;
    harverApplicationEmail?: string;
    harverVacancyId?: string;
    harverApplicationId?: string;
    harverApplicationLanguage?: string;
    harverApplicantName?: string; // TODO: How can we get candidate's name?
    harverApplicantDetailPage?: string;
    harverJourneyId?: string; // TODO: These are accessible, but are they needed?
    harverJourneyStepId?: string; // TODO: These are accessible, but are they needed?
};

export enum ChatbotPages {
    ErrorPage = 'ErrorPage',
    NotFoundPage = 'NotFoundPage',
    JourneyPage = 'JourneyPage',
    LandingPage = 'LandingPage',
    VacancyLandingPage = 'VacancyLandingPage',
}

export interface ChatbotHookDependencies {
    // NOTE: Is there a better solution for this typing?
    data:
        | JourneyPageChatbotDependencies
        | LandingPageChatbotDependencies
        | ErrorPageChatbotDependencies
        | VacancyLandingPageDependencies;
    language?: string;
}

// TODO: Add email here, which can be detected with a future service
export type JourneyPageChatbotDependencies = {
    journeyStepId: string;
    journeyId: string;
    currentPage: string;
    moduleConfig: Record<string, any>;

    harverAccountId: string;
    harverAccountName: string;
    harverVacancyId: string;
    harverApplicationEmail: string;
    harverApplicationId: string;
};

export type LandingPageChatbotDependencies = {
    flowConfig: Record<string, any>;
    email: string;
    currentPage: ChatbotPages.LandingPage;
};

export type ErrorPageChatbotDependencies = {
    currentPage: ChatbotPages.ErrorPage;

    harverAccountId?: string;
    harverAccountName?: string;
    harverVacancyId?: string;
    harverApplicationEmail?: string;
    harverApplicationId?: string;
};

export type VacancyLandingPageDependencies = {
    email: string;
    currentPage: ChatbotPages.LandingPage;
};

export interface UseChatbotOutput {
    openChatbotWindow(): Promise<void>;
    setChatbotDependencies: React.Dispatch<React.SetStateAction<ChatbotHookDependencies>>;
}
