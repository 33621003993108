"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.captureRuntimeArguments = void 0;
const captureRuntimeArguments = (object, property) => {
    const fn = object[property];
    if (typeof fn !== 'function') {
        throw new Error(`Property ${String(property)} is not a function`);
    }
    const capture = [];
    object[property] = ((...args) => {
        // Push the arguments into the array to be read out later (passed by reference)
        capture.push(...args);
        // Restore the original function
        object[property] = fn;
        // Call it with the original context and arguments
        return fn.apply(object, args);
    });
    return capture;
};
exports.captureRuntimeArguments = captureRuntimeArguments;
