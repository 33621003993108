"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NOASubtestNormGroup = exports.NOASubtestDifficulty = exports.NOAQuestionOptionType = exports.NOAQuestionType = exports.NOASubtestName = exports.NOASubtestType = void 0;
var NOASubtestType;
(function (NOASubtestType) {
    NOASubtestType["components"] = "components";
    NOASubtestType["exclusion"] = "exclusion";
    NOASubtestType["wordAnalogies"] = "wordAnalogies";
    NOASubtestType["numberSequence"] = "numberSequence";
    NOASubtestType["checkingTest"] = "checkingTest";
})(NOASubtestType = exports.NOASubtestType || (exports.NOASubtestType = {}));
var NOASubtestName;
(function (NOASubtestName) {
    NOASubtestName["components"] = "Components";
    NOASubtestName["exclusion"] = "Exclusion";
    NOASubtestName["wordAnalogies"] = "Word Analogies";
    NOASubtestName["numberSequence"] = "Number Sequences";
    NOASubtestName["checkingTest"] = "Checking";
})(NOASubtestName = exports.NOASubtestName || (exports.NOASubtestName = {}));
var NOAQuestionType;
(function (NOAQuestionType) {
    NOAQuestionType["string"] = "string";
    NOAQuestionType["image"] = "image";
})(NOAQuestionType = exports.NOAQuestionType || (exports.NOAQuestionType = {}));
var NOAQuestionOptionType;
(function (NOAQuestionOptionType) {
    NOAQuestionOptionType["string"] = "string";
    NOAQuestionOptionType["image"] = "image";
    NOAQuestionOptionType["boolean"] = "boolean";
})(NOAQuestionOptionType = exports.NOAQuestionOptionType || (exports.NOAQuestionOptionType = {}));
var NOASubtestDifficulty;
(function (NOASubtestDifficulty) {
    NOASubtestDifficulty["medium"] = "medium";
    NOASubtestDifficulty["high"] = "high";
})(NOASubtestDifficulty = exports.NOASubtestDifficulty || (exports.NOASubtestDifficulty = {}));
var NOASubtestNormGroup;
(function (NOASubtestNormGroup) {
    NOASubtestNormGroup["medium"] = "medium";
    NOASubtestNormGroup["bachelor"] = "bachelor";
    NOASubtestNormGroup["master"] = "master";
})(NOASubtestNormGroup = exports.NOASubtestNormGroup || (exports.NOASubtestNormGroup = {}));
