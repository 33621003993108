import { datadogRum } from '@datadog/browser-rum';
import { ContextValue, Datacenter } from '@datadog/browser-core';

const init = ({ clientToken, applicationId }) => {
    datadogRum.init({
        applicationId,
        clientToken,
        datacenter: Datacenter.US,
        sessionSampleRate: 10,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: false,
    });
};

const addGlobalContext = (key: string, value: ContextValue) => {
    datadogRum.setGlobalContextProperty(key, value);
};

export const realtimeUserMonitoringService = {
    init,
    addGlobalContext,
};
