"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.splitWords = exports.getWordSeparator = void 0;
const i18n_1 = require("../i18n");
const wordSeparatorMap = {
    'zh-CN': '',
    'ja-JP': '',
};
const getWordSeparator = (language = i18n_1.Locale['en-US'], isCustomSeparatorEnabled = false) => {
    if (isCustomSeparatorEnabled && wordSeparatorMap.hasOwnProperty(language)) {
        return wordSeparatorMap[language];
    }
    return ' ';
};
exports.getWordSeparator = getWordSeparator;
const wordSplitMap = {
    'zh-CN': (text, isCustomSeparatorEnabled = false) => text.split((0, exports.getWordSeparator)(i18n_1.Locale['zh-CN'], isCustomSeparatorEnabled)),
    'ja-JP': (text, isCustomSeparatorEnabled = false) => text.split((0, exports.getWordSeparator)(i18n_1.Locale['ja-JP'], isCustomSeparatorEnabled)),
};
const splitWords = (text = '', language = i18n_1.Locale['en-US'], isCustomSeparatorEnabled = false) => {
    if (isCustomSeparatorEnabled && wordSplitMap.hasOwnProperty(language)) {
        return wordSplitMap[language].call(null, text, isCustomSeparatorEnabled);
    }
    return text.split((0, exports.getWordSeparator)(language, isCustomSeparatorEnabled));
};
exports.splitWords = splitWords;
