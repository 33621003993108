"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleType = void 0;
// eslint-disable-next-line no-shadow
var ModuleType;
(function (ModuleType) {
    ModuleType["ContentPageModule"] = "ContentPageModule";
    ModuleType["VacancyQuestionsModule"] = "VacancyQuestionsModule";
    ModuleType["SpokenLanguageTestModule"] = "SpokenLanguageTestModule";
    ModuleType["RedirectToClientCandidateModule"] = "RedirectToClientCandidateModule";
    ModuleType["ChatProficiencyAssessmentModule"] = "ChatProficiencyAssessmentModule";
    ModuleType["TypingModule"] = "TypingModule";
    ModuleType["NOAModule"] = "NOAModule";
    ModuleType["LearningAgilityModule"] = "LearningAgilityModule";
    ModuleType["PersonalityQuestionnaireModule"] = "PersonalityQuestionnaireModule";
    ModuleType["JobKnowledgeTestModule"] = "JobKnowledgeTestModule";
    ModuleType["SystemCheckerModule"] = "SystemCheckerModule";
    ModuleType["InternetSpeedTestModule"] = "InternetSpeedTestModule";
    ModuleType["PersonalityPrintModule"] = "PersonalityPrintModule";
    ModuleType["VirtualInterviewModule"] = "VirtualInterviewModule";
    ModuleType["SituationalJudgmentTestModule"] = "SituationalJudgmentTestModule";
    ModuleType["AvailabilityModule"] = "AvailabilityModule";
    ModuleType["PersonalInformationModule"] = "PersonalInformationModule";
    ModuleType["LanguageTestModule"] = "LanguageTestModule";
    ModuleType["MultiLocationPickerModule"] = "MultiLocationPickerModule";
    ModuleType["PymetricsModule"] = "PymetricsModule";
})(ModuleType = exports.ModuleType || (exports.ModuleType = {}));
