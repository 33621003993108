"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InternetSpeedTestType = void 0;
var InternetSpeedTestType;
(function (InternetSpeedTestType) {
    InternetSpeedTestType["DownloadSpeed"] = "downloadSpeed";
    InternetSpeedTestType["UploadSpeed"] = "uploadSpeed";
    InternetSpeedTestType["Latency"] = "latency";
    InternetSpeedTestType["Jitter"] = "jitter";
})(InternetSpeedTestType = exports.InternetSpeedTestType || (exports.InternetSpeedTestType = {}));
