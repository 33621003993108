import { LoginOrigin } from '@harver/journey-shared';
import React, { createContext, FC, ReactNode, useCallback, useContext, useReducer } from 'react';

interface UserState {
    isLoggedIn: boolean;
    hasInteractedWithLanguage: boolean;
    isResumingJourney: boolean;
    email: string;
    journeyId: string;
    loginOrigin: LoginOrigin;
}

interface UserProviderProps {
    initialState?: UserState;
    children: ReactNode;
}

interface UserAction {
    type: UserActionType;
    payload: unknown;
}

type UserDispatch = (params: UserAction) => void;

type UserSetIsLoggedIn = () => (isLoggedIn: boolean) => void;

type UserSetLoginOrigin = () => (loginOrigin: LoginOrigin) => void;

type UserSetHasInteractedWithLanguage = () => (hasInteractedWithLanguage: boolean) => void;

type UserSetIsResumingJourney = () => (isResumingJourney: boolean) => void;

type UserSetString = () => (str: string) => void;

export enum UserActionType {
    IsLoggedIn,
    HasInteractedWithLanguage,
    IsResumingJourney,
    SetEmail,
    SetJourneyId,
    SetLoginOrigin,
}

const UserStateContext = createContext<UserState | undefined>(undefined);
const UserDispatchContext = createContext<UserDispatch | undefined>(undefined);

const userDefaultState: UserState = {
    isLoggedIn: false,
    hasInteractedWithLanguage: false,
    isResumingJourney: false,
    email: '',
    journeyId: '',
    loginOrigin: LoginOrigin.landingPage,
};

const userReducer = (state: UserState, { type, payload }: UserAction): UserState => {
    switch (type) {
        case UserActionType.IsLoggedIn: {
            return { ...state, isLoggedIn: payload as boolean };
        }
        case UserActionType.HasInteractedWithLanguage: {
            return { ...state, hasInteractedWithLanguage: payload as boolean };
        }
        case UserActionType.IsResumingJourney: {
            return { ...state, isResumingJourney: payload as boolean };
        }
        case UserActionType.SetEmail: {
            return { ...state, email: payload as string };
        }
        case UserActionType.SetJourneyId: {
            return { ...state, journeyId: payload as string };
        }
        case UserActionType.SetLoginOrigin: {
            return { ...state, loginOrigin: payload as LoginOrigin };
        }
        default: {
            throw new Error(`UserContext unhandled action type: ${type}`);
        }
    }
};

export const UserProvider: FC<UserProviderProps> = ({
    initialState = userDefaultState,
    children,
}: UserProviderProps) => {
    const [state, dispatch] = useReducer(userReducer, initialState);

    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
        </UserStateContext.Provider>
    );
};

export const useUserState = () => {
    return useContext(UserStateContext);
};

export const useUserDispatch = () => {
    return useContext(UserDispatchContext);
};

export const useUserIsLoggedIn = () => {
    const { isLoggedIn } = useUserState() || {};

    return isLoggedIn;
};

export const useUserSetIsLoggedIn: UserSetIsLoggedIn = () => {
    const dispatch = useUserDispatch();

    return useCallback(
        (isLoggedIn: boolean) => {
            dispatch({ type: UserActionType.IsLoggedIn, payload: isLoggedIn });
        },
        [dispatch],
    );
};

export const useUserLoginOrigin = () => {
    const { loginOrigin } = useUserState() || {};

    return loginOrigin ?? LoginOrigin.landingPage;
};

export const useUserSetLoginOrigin: UserSetLoginOrigin = () => {
    const dispatch = useUserDispatch();

    return useCallback(
        (loginOrigin: LoginOrigin) => {
            dispatch({ type: UserActionType.SetLoginOrigin, payload: loginOrigin });
        },
        [dispatch],
    );
};

export const useUserHasInteractedWithLanguage = () => {
    const { hasInteractedWithLanguage } = useUserState();

    return hasInteractedWithLanguage;
};

export const useUserSetHasInteractedWithLanguage: UserSetHasInteractedWithLanguage = () => {
    const dispatch = useUserDispatch();

    return useCallback(
        (hasInteractedWithLanguage: boolean): void => {
            dispatch({
                type: UserActionType.HasInteractedWithLanguage,
                payload: hasInteractedWithLanguage,
            });
        },
        [dispatch],
    );
};

export const useUserIsResumingJourney = () => {
    const { isResumingJourney } = useUserState() || {};

    return isResumingJourney;
};

export const useUserSetIsResumingJourney: UserSetIsResumingJourney = () => {
    const dispatch = useUserDispatch();

    return (isResumingJourney: boolean): void => {
        dispatch({ type: UserActionType.IsResumingJourney, payload: isResumingJourney });
    };
};

export const useUserEmail = () => {
    const { email } = useUserState() || {};

    return email;
};

export const useSetUserEmail: UserSetString = () => {
    const dispatch = useUserDispatch();

    return useCallback(
        (email: string) => {
            dispatch({ type: UserActionType.SetEmail, payload: email });
        },
        [dispatch],
    );
};

export const useUserJourneyId = () => {
    const { journeyId } = useUserState() || {};

    return journeyId;
};

export const useSetUserJourneyId: UserSetString = () => {
    const dispatch = useUserDispatch();

    return (journeyId: string): void => {
        dispatch({ type: UserActionType.SetJourneyId, payload: journeyId });
    };
};
