import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { Locale } from '@harver/journey-shared';
import { ReactIntl } from '@harver/shared-ui';

type LocalizationProviderProps = {
    children: ReactNode;
    initialMessages?: Record<string, string>;
    initialLanguage?: Locale;
    initialLanguages?: Locale[];
};

type SetMessagesDispatch = (newMessages: Record<string, string>) => void;
type SetLanguageDispatch = (newLanguage: Locale) => void;
type SetLanguagesDispatch = (newLanguages: Locale[]) => void;

const LocalizationSetMessagesContext = createContext<SetMessagesDispatch | undefined>(undefined);
const LanguageStateContext = createContext<Locale | undefined>(undefined);
const LanguagesStateContext = createContext<Locale[] | undefined>(undefined);
const LanguageSetStateContext = createContext<SetLanguageDispatch | undefined>(undefined);
const LanguagesSetStateContext = createContext<SetLanguagesDispatch | undefined>(undefined);

export const LocalizationProvider: React.FC<LocalizationProviderProps> = ({
    children,
    initialMessages,
    initialLanguage,
    initialLanguages,
}: LocalizationProviderProps) => {
    const [messages, setMessages] = useState(initialMessages);
    const [language, setLanguage] = useState<Locale>(initialLanguage);
    const [flowLanguages, setFlowLanguages] = useState<Locale[]>(initialLanguages);

    const updateMessages = useCallback(
        (addedMessages) => {
            setMessages((prevMessages) => ({
                ...prevMessages,
                ...addedMessages,
            }));
        },
        [setMessages],
    );
    return (
        <LanguageStateContext.Provider value={language}>
            <LanguageSetStateContext.Provider value={setLanguage}>
                <LanguagesStateContext.Provider value={flowLanguages}>
                    <LanguagesSetStateContext.Provider value={setFlowLanguages}>
                        <LocalizationSetMessagesContext.Provider value={updateMessages}>
                            <ReactIntl.IntlProvider locale={language} messages={messages}>
                                {children}
                            </ReactIntl.IntlProvider>
                        </LocalizationSetMessagesContext.Provider>
                    </LanguagesSetStateContext.Provider>
                </LanguagesStateContext.Provider>
            </LanguageSetStateContext.Provider>
        </LanguageStateContext.Provider>
    );
};

export const useLocalizationSetMessages = () => {
    return useContext(LocalizationSetMessagesContext);
};

export const useLanguageState = () => {
    return useContext(LanguageStateContext);
};

export const useLanguageSetState = () => {
    return useContext(LanguageSetStateContext);
};

export const useLanguagesState = () => {
    return useContext(LanguagesStateContext);
};

export const useLanguagesSetState = () => {
    return useContext(LanguagesSetStateContext);
};
