import useApiFetch, { HttpError } from './useApiFetch';

const useFeatureToggle = (
    name: string,
    context?: Record<string, string>,
): [boolean, boolean | undefined, HttpError | undefined] => {
    const searchParams = context ? `?${new URLSearchParams(context).toString()}` : '';
    const [data, loading, error] = useApiFetch<boolean>({
        path: `/feature/isEnabled/${name}${searchParams}`,
    });

    return [data, loading, error];
};

export default useFeatureToggle;
