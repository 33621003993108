import React, { createContext, FC, ReactNode, useContext } from 'react';

export type ErrorHandlingArgs = {
    errorId?: string;
    code: string;
    errorCode?: string;
    httpStatusCode?: number;
} & Record<string, any>;

interface ErrorContextConfig {
    children: ReactNode;
    initialValue?: (error: ErrorHandlingArgs) => void;
}

const ErrorContext = createContext<(error: ErrorHandlingArgs) => void>(() => {});

const ErrorContextProvider: FC<ErrorContextConfig> = ({ children, initialValue }) => {
    return <ErrorContext.Provider value={initialValue}>{children}</ErrorContext.Provider>;
};

const useSetErrorDetails = () => {
    return useContext(ErrorContext);
};

export { ErrorContext, ErrorContextProvider, useSetErrorDetails };
