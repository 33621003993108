"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SituationalJudgmentTestPageType = exports.SituationalJudgmentTestVariant = exports.SituationalJudgmentTestMediaType = void 0;
var SituationalJudgmentTestMediaType;
(function (SituationalJudgmentTestMediaType) {
    SituationalJudgmentTestMediaType["Video"] = "video";
    SituationalJudgmentTestMediaType["Image"] = "image";
})(SituationalJudgmentTestMediaType = exports.SituationalJudgmentTestMediaType || (exports.SituationalJudgmentTestMediaType = {}));
var SituationalJudgmentTestVariant;
(function (SituationalJudgmentTestVariant) {
    SituationalJudgmentTestVariant["Simple"] = "simple";
    SituationalJudgmentTestVariant["Regular"] = "regular";
})(SituationalJudgmentTestVariant = exports.SituationalJudgmentTestVariant || (exports.SituationalJudgmentTestVariant = {}));
var SituationalJudgmentTestPageType;
(function (SituationalJudgmentTestPageType) {
    SituationalJudgmentTestPageType["Assessment"] = "assessment";
    SituationalJudgmentTestPageType["Content"] = "content";
})(SituationalJudgmentTestPageType = exports.SituationalJudgmentTestPageType || (exports.SituationalJudgmentTestPageType = {}));
