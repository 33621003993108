import React, { PureComponent } from 'react';

interface ErrorBoundaryProps {
    renderErrorPage: (error: Error) => React.ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
    error?: Error;
}

// TODO:
// Add proper return types.
class ErrorBoundary extends PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
    state: ErrorBoundaryState = { hasError: false };

    static getDerivedStateFromError(error: Error) {
        return { hasError: true, error };
    }

    render() {
        if (this.state.hasError) {
            return this.props.renderErrorPage(this.state.error);
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
