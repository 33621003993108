"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SystemCheckerResultStatus = exports.SystemCheckerCheckProperty = void 0;
var SystemCheckerCheckProperty;
(function (SystemCheckerCheckProperty) {
    SystemCheckerCheckProperty["freeSpace"] = "freeSpace";
    SystemCheckerCheckProperty["processor"] = "processor";
    SystemCheckerCheckProperty["ram"] = "ram";
    SystemCheckerCheckProperty["platform"] = "platform";
    SystemCheckerCheckProperty["browser"] = "browser";
    SystemCheckerCheckProperty["machineType"] = "machineType";
    SystemCheckerCheckProperty["downloadSpeed"] = "downloadSpeed";
    SystemCheckerCheckProperty["uploadSpeed"] = "uploadSpeed";
    SystemCheckerCheckProperty["latency"] = "latency";
    SystemCheckerCheckProperty["connectionType"] = "connectionType";
    SystemCheckerCheckProperty["processorNumberOfCores"] = "processorNumberOfCores";
    SystemCheckerCheckProperty["resolution"] = "resolution";
    SystemCheckerCheckProperty["jitter"] = "jitter";
    SystemCheckerCheckProperty["manufacturer"] = "manufacturer";
    SystemCheckerCheckProperty["model"] = "model";
    SystemCheckerCheckProperty["biosDate"] = "biosDate";
    SystemCheckerCheckProperty["macAddress"] = "macAddress";
    SystemCheckerCheckProperty["location"] = "location";
    SystemCheckerCheckProperty["isVPN"] = "isVPN";
    SystemCheckerCheckProperty["isTor"] = "isTor";
    SystemCheckerCheckProperty["virtualMachine"] = "virtualMachine";
    SystemCheckerCheckProperty["buildVersion"] = "buildVersion";
    SystemCheckerCheckProperty["buildNumber"] = "buildNumber";
    SystemCheckerCheckProperty["ipAddress"] = "ipAddress";
    SystemCheckerCheckProperty["antiVirusProduct"] = "antiVirusProduct";
    SystemCheckerCheckProperty["antiSpyware"] = "antiSpyware";
    SystemCheckerCheckProperty["firewallProduct"] = "firewallProduct";
    SystemCheckerCheckProperty["windowsUpdateEnabled"] = "windowsUpdateEnabled";
    SystemCheckerCheckProperty["windowsUpdated"] = "windowsUpdated";
    SystemCheckerCheckProperty["secondaryMonitor"] = "secondaryMonitor";
    SystemCheckerCheckProperty["serial"] = "serial";
    SystemCheckerCheckProperty["osArchitecture"] = "osArchitecture";
    SystemCheckerCheckProperty["cpuBrand"] = "cpuBrand";
    SystemCheckerCheckProperty["cpuManufacturer"] = "cpuManufacturer";
})(SystemCheckerCheckProperty = exports.SystemCheckerCheckProperty || (exports.SystemCheckerCheckProperty = {}));
var SystemCheckerResultStatus;
(function (SystemCheckerResultStatus) {
    SystemCheckerResultStatus["PASS"] = "pass";
    SystemCheckerResultStatus["FAIL"] = "fail";
    SystemCheckerResultStatus["UNKNOWN"] = "unknown";
})(SystemCheckerResultStatus = exports.SystemCheckerResultStatus || (exports.SystemCheckerResultStatus = {}));
