"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobKnowledgeTestYesNoOptionId = exports.JobKnowledgeTestQuestionType = void 0;
var JobKnowledgeTestQuestionType;
(function (JobKnowledgeTestQuestionType) {
    JobKnowledgeTestQuestionType["YesNo"] = "yesNo";
    JobKnowledgeTestQuestionType["Radio"] = "radio";
    JobKnowledgeTestQuestionType["Checkbox"] = "checkbox";
})(JobKnowledgeTestQuestionType = exports.JobKnowledgeTestQuestionType || (exports.JobKnowledgeTestQuestionType = {}));
var JobKnowledgeTestYesNoOptionId;
(function (JobKnowledgeTestYesNoOptionId) {
    JobKnowledgeTestYesNoOptionId["Yes"] = "yes";
    JobKnowledgeTestYesNoOptionId["No"] = "no";
})(JobKnowledgeTestYesNoOptionId = exports.JobKnowledgeTestYesNoOptionId || (exports.JobKnowledgeTestYesNoOptionId = {}));
