import { ok } from 'assert';
import { getChatbotVariables, initializeCertainly, webchatKeyInit, Certainly } from './helpers';
import { ChatbotHookDependencies, UseChatbotOutput } from './types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { logger } from '../../utils/logger';
import { loadScript } from './scriptLoader';
import useFeatureToggle from '../../hooks/useFeatureToggle';
import { OpenChatbot, SetConversationalFields, toZendeskConversationFields } from './zendeskBridge';

const isProduction = process.env.REACT_APP_DEPLOYMENT_ENV === 'production';

const BOTXO_URL = 'https://app.certainly.io/sdk/webchat.js';
const ZENDESK_URL = 'https://static.zdassets.com/ekr/snippet.js';

const BOTXO_KEY = process.env.REACT_APP_BOTXO_KEY;
const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY;

ok(BOTXO_KEY && ZENDESK_KEY);

// It's ok-ish to disable the cognitive complexity error as
// 1) It's not an easy refactoring to extract the hook into two different hooks because of their lifecycle; and
// 2) soon™ we should only have zendesk anyway
// eslint-disable-next-line sonarjs/cognitive-complexity
export const useChatbot = (chatbotHookDependencies?: ChatbotHookDependencies): UseChatbotOutput => {
    const { webchatKey, isNewSession } = useMemo(webchatKeyInit, []);
    const [chatbotDependencies, setChatbotDependencies] = useState(chatbotHookDependencies);

    // NOTE: This does not take account id in context!
    // You get all or nothing!
    const [zendeskEnabled, ffLoading] = useFeatureToggle('zendeskChatbot');

    useEffect(() => {
        if (ffLoading) {
            return;
        }

        const script = zendeskEnabled
            ? { id: 'ze-snippet', src: `${ZENDESK_URL}?key=${ZENDESK_KEY}` }
            : { id: 'certainly-chatbot', src: BOTXO_URL };

        loadScript(script);
    }, [zendeskEnabled, ffLoading]);

    const zendeskContext = useMemo(
        () => () => {
            if (!chatbotDependencies) return;
            const context = getChatbotVariables(chatbotDependencies.data, chatbotDependencies.language);
            return toZendeskConversationFields(context);
        },
        [chatbotDependencies],
    );

    // Zendesk will open the chat bot window, if the candidate has opened before and they
    // refresh the page some later time. We have to reset the conversational fields then,
    // to make sure they will show up correctly in the Zendesk Support Panel.
    useEffect(() => {
        if (zendeskEnabled) SetConversationalFields(zendeskContext());
    }, [zendeskEnabled, zendeskContext]);

    const certainlyContext = useMemo(() => {
        if (!chatbotDependencies || zendeskEnabled) {
            return undefined;
        }

        const { data, language } = chatbotDependencies;
        return {
            id: BOTXO_KEY,
            botxoUnbranded: 1,
            cvars: getChatbotVariables(data, language),
            mode: (!isProduction || isNewSession) && 'clear_past_conversations',
            webchatKey,
            noDelayMode: !isProduction,
            debuggerMode: isProduction ? 0 : 1,
        };
    }, [chatbotDependencies, isNewSession, webchatKey, zendeskEnabled]);

    const closeCertainlyWindow = useCallback(
        (callback?: () => void) => {
            Certainly().widgetStatus(
                {
                    action: 'close',
                    webchatKey: certainlyContext.webchatKey,
                },
                callback,
            );
        },
        [certainlyContext],
    );

    const openCertainlyChat = useCallback(
        (callback?: () => void) => {
            Certainly().widgetStatus(
                {
                    action: 'open',
                    webchatKey: certainlyContext.webchatKey,
                },
                callback,
            );
        },
        [certainlyContext],
    );

    const initAndOpenCertainlyChat = useCallback(async (): Promise<void> => {
        logger.debug('Open chat window');
        if (certainlyContext) {
            await initializeCertainly(certainlyContext);
            closeCertainlyWindow(openCertainlyChat);
        }
    }, [openCertainlyChat, closeCertainlyWindow, certainlyContext]);

    const openZendeskChatbot = () => {
        SetConversationalFields(zendeskContext());
        OpenChatbot();
        return Promise.resolve();
    };

    return {
        openChatbotWindow: zendeskEnabled ? openZendeskChatbot : initAndOpenCertainlyChat,
        setChatbotDependencies,
    };
};
