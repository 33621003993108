import { Session, UserSession, Id } from '@harver/journey-shared';
import { apiGet, apiPost } from '../utils/httpClient';

interface RegisterPayload {
    email: string;
    flowId: Id;
    language: string;
}

export const getSession = (): Promise<UserSession> => {
    return apiGet<UserSession>('/auth/session');
};

// TODO:
// - We could rename the endpoint to be `/auth/register`.
export const register = (payload: RegisterPayload): Promise<Session> => {
    return apiPost<Session>('/auth', payload);
};
