import { datadogLogs, HandlerType, StatusType } from '@datadog/browser-logs';
import { Context, ContextValue, Datacenter, ErrorOrigin } from '@datadog/browser-core';
import { DeploymentEnvironment } from '../data/environments';

type Payload = object | string;

export interface LoggerInitConfig {
    logLevel: string;
    applicationId: string;
    clientToken: string;
}

const shouldLogToConsole: boolean = process.env.REACT_APP_DEPLOYMENT_ENV === DeploymentEnvironment.local;

const parsePayload = (payload: Payload): string => {
    if (typeof payload === 'string') {
        return payload;
    }

    if (typeof payload === 'object') {
        return JSON.stringify(payload);
    }
};

const log = (payload: Payload, context: Context, status: StatusType): void => {
    const message = parsePayload(payload);
    datadogLogs.logger.log(message, context, status);

    if (shouldLogToConsole) {
        // eslint-disable-next-line no-console, security/detect-object-injection
        context != null ? console[status](message, context) : console[status](message);
    }
};

// This is a special representation of an error, recognized by datadog.
const createErrorContext = (error: Error, additionalContext?: Context) => {
    const additionalErrorContext: Record<string, any> = {};

    // add context from ApiResponseError
    if ('payload' in error) {
        additionalErrorContext.payload = (error as any).payload;
    }

    return {
        error: {
            message: error.message || 'No message',
            stack: error.stack || 'No stack',
            kind: error.name || 'No error name',
            origin: ErrorOrigin.SOURCE,
            ...additionalErrorContext,
        },
        ...additionalContext,
    };
};

export const logger = {
    init: ({ applicationId, clientToken, logLevel }: LoggerInitConfig): void => {
        datadogLogs.init({
            clientToken,
            datacenter: Datacenter.US,
            forwardErrorsToLogs: true,
            sessionSampleRate: 100,
        });

        datadogLogs.logger.setLevel(logLevel as StatusType);
        datadogLogs.setGlobalContext({
            client: 'candidate_journey',
            application_id: applicationId,
        });

        if (shouldLogToConsole) {
            // we set it to "silent" and then log the message with usual console for local development as it is more readable
            datadogLogs.logger.setHandler(HandlerType.silent);
        }

        logger.debug('logger is initialized');
    },

    debug: (payload: Payload, context?: Context): void => {
        log(payload, context, StatusType.debug);
    },
    info: (payload: Payload, context?: Context): void => {
        log(payload, context, StatusType.info);
    },
    warn: (payload: Payload, context?: Context): void => {
        log(payload, context, StatusType.warn);
    },

    error: (message, error: Error, additionalContext?: Context): void => {
        const context = createErrorContext(error, additionalContext);

        log(message, context, StatusType.error);
    },

    addGlobalContext: (key: string, value: ContextValue) => {
        datadogLogs.setGlobalContextProperty(key, value);
    },
};
