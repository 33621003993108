import { logger } from '../../utils/logger';
import { ChatbotData } from './types';

type Field = { id: string; value: string | number | boolean };

// Check out the field map: https://harver.zendesk.com/admin/objects-rules/tickets/ticket-fields
// (Search for Harvey Bot to find the relevant fields)
export const toZendeskConversationFields = (data: ChatbotData): Field[] =>
    Object.entries({
        '21626905468945': data.harverAccountId,
        '22456213810577': data.harverAccountName,
        '22456523317393': data.harverApplicantDetailPage,
        '22456278645649': data.harverApplicationEmail,
        '21626935756817': data.harverApplicationId,
        '22456419208465': data.harverApplicationLanguage,
        '22456473939601': data.harverApplicantName,
        '22455229906321': data.currentPage,
        '22456503785873': data.harverJourneyId,
        '22456592995985': data.harverJourneyStepId,
        '22456080589201': data.pageUrl,
        '21626926347537': data.harverVacancyId,
    }).map(([id, value]) => ({ id, value }));

const Ze = (...args: unknown[]): void => {
    if (!('zE' in window)) {
        const msg =
            'An attempt to use Zendesk was detected.' +
            'Though there is no instance available.' +
            'Nothing will happen.';
        logger.warn(msg);
        return;
    }

    try {
        (window.zE as (...args: unknown[]) => void)(...args);
    } catch (err) {
        logger.error('Error calling zendesk api', err, { args: args as never });
    }
};

/* Public API to interact with Zendesk client after script is downloaded */

export const OpenChatbot = () => Ze('messenger', 'open');

export const SetConversationalFields = (fields: Field[]) => Ze('messenger:set', 'conversationFields', fields);
