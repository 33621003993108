import React, { createContext, FC, useContext, useState, ReactNode } from 'react';
import { FlowConfigViewModel } from '@harver/journey-shared';

interface FlowConfigProviderProps {
    children: ReactNode;
    initialValue?: FlowConfigViewModel;
}

type FlowConfigDispatch = (newFlowConfig: FlowConfigViewModel) => void;

const FlowConfigStateContext = createContext<FlowConfigViewModel>(undefined);
const FlowConfigSetStateContext = createContext<FlowConfigDispatch | undefined>(undefined);

export const FlowConfigProvider: FC<FlowConfigProviderProps> = ({
    children,
    initialValue,
}: FlowConfigProviderProps) => {
    const [state, setState] = useState<FlowConfigViewModel>(initialValue);

    return (
        <FlowConfigStateContext.Provider value={state}>
            <FlowConfigSetStateContext.Provider value={setState}>{children}</FlowConfigSetStateContext.Provider>
        </FlowConfigStateContext.Provider>
    );
};

export const useFlowConfigState = () => {
    return useContext(FlowConfigStateContext);
};

export const useFlowConfigSetState = () => {
    return useContext(FlowConfigSetStateContext);
};
