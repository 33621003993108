export const saveDataToSessionStorage = <T = any>(key: string, data: T): void => {
    sessionStorage.setItem(key, JSON.stringify(data));
};

export const getDataFromSessionStorage = <T = any>(key: string): T | null => {
    return (JSON.parse(sessionStorage.getItem(key)) as unknown) as T | null;
};

export const removeDataFromSessionStorage = (key: string): void => {
    sessionStorage.removeItem(key);
};
