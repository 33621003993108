"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Countries = exports.VacancyQuestionsYesNoOptionId = exports.VacancyQuestionsQuestionType = exports.VACANCY_QUESTIONS_FILE_ALLOWED_FORMATS = exports.VACANCY_QUESTIONS_ADDRESS_OPTION_MAX_LENGTH = exports.VACANCY_QUESTIONS_ANSWER_DEFAULT_VALUE = exports.VACANCY_QUESTIONS_TEXT_AREA_MAX_LENGTH = void 0;
exports.VACANCY_QUESTIONS_TEXT_AREA_MAX_LENGTH = 250;
exports.VACANCY_QUESTIONS_ANSWER_DEFAULT_VALUE = '';
exports.VACANCY_QUESTIONS_ADDRESS_OPTION_MAX_LENGTH = 100;
exports.VACANCY_QUESTIONS_FILE_ALLOWED_FORMATS = [
    '.png',
    '.jpeg',
    '.jpg',
    '.pdf',
    '.docx',
    '.doc',
];
// eslint-disable-next-line no-shadow
var VacancyQuestionsQuestionType;
(function (VacancyQuestionsQuestionType) {
    VacancyQuestionsQuestionType["text"] = "text";
    VacancyQuestionsQuestionType["textarea"] = "textarea";
    VacancyQuestionsQuestionType["date"] = "date";
    VacancyQuestionsQuestionType["dropdown"] = "dropdown";
    VacancyQuestionsQuestionType["checkbox"] = "checkbox";
    VacancyQuestionsQuestionType["yesNo"] = "yesNo";
    VacancyQuestionsQuestionType["file"] = "file";
    VacancyQuestionsQuestionType["radio"] = "radio";
    VacancyQuestionsQuestionType["address"] = "address";
})(VacancyQuestionsQuestionType = exports.VacancyQuestionsQuestionType || (exports.VacancyQuestionsQuestionType = {}));
// eslint-disable-next-line no-shadow
var VacancyQuestionsYesNoOptionId;
(function (VacancyQuestionsYesNoOptionId) {
    VacancyQuestionsYesNoOptionId["Yes"] = "yes";
    VacancyQuestionsYesNoOptionId["No"] = "no";
})(VacancyQuestionsYesNoOptionId = exports.VacancyQuestionsYesNoOptionId || (exports.VacancyQuestionsYesNoOptionId = {}));
// NOTE: translations for this list are located in CJ client. Please update those as well if you change this list.
exports.Countries = [
    {
        code: 'AF',
        defaultLabel: 'Afghanistan',
        phone: '+93',
        longCode: 'AFG',
    },
    {
        code: 'AL',
        defaultLabel: 'Albania',
        phone: '+355',
        longCode: 'ALB',
    },
    {
        code: 'DZ',
        defaultLabel: 'Algeria',
        phone: '+213',
        longCode: 'DZA',
    },
    {
        code: 'AX',
        defaultLabel: 'Alland Islands',
        phone: '+358',
        longCode: 'ALA',
    },
    {
        code: 'AS',
        defaultLabel: 'American Samoa',
        phone: '+1-684',
        longCode: 'ASM',
    },
    {
        code: 'AD',
        defaultLabel: 'Andorra',
        phone: '+376',
        longCode: 'AND',
    },
    {
        code: 'AO',
        defaultLabel: 'Angola',
        phone: '+244',
        longCode: 'AGO',
    },
    {
        code: 'AI',
        defaultLabel: 'Anguilla',
        phone: '+1-264',
        longCode: 'AIA',
    },
    {
        code: 'AQ',
        defaultLabel: 'Antarctica',
        phone: '+672',
        longCode: 'ATA',
    },
    {
        code: 'AG',
        defaultLabel: 'Antigua and Barbuda',
        phone: '+1-268',
        longCode: 'ATG',
    },
    {
        code: 'AR',
        defaultLabel: 'Argentina',
        phone: '+54',
        longCode: 'ARG',
    },
    {
        code: 'AM',
        defaultLabel: 'Armenia',
        phone: '+374',
        longCode: 'ARM',
    },
    { code: 'AW', defaultLabel: 'Aruba', phone: '+297', longCode: 'ABW' },
    {
        code: 'AU',
        defaultLabel: 'Australia',
        phone: '+61',
        longCode: 'AUS',
    },
    {
        code: 'AT',
        defaultLabel: 'Austria',
        phone: '+43',
        longCode: 'AUT',
    },
    {
        code: 'AZ',
        defaultLabel: 'Azerbaijan',
        phone: '+994',
        longCode: 'AZE',
    },
    {
        code: 'BS',
        defaultLabel: 'Bahamas',
        phone: '+1-242',
        longCode: 'BHS',
    },
    {
        code: 'BH',
        defaultLabel: 'Bahrain',
        phone: '+973',
        longCode: 'BHR',
    },
    {
        code: 'BD',
        defaultLabel: 'Bangladesh',
        phone: '+880',
        longCode: 'BGD',
    },
    {
        code: 'BB',
        defaultLabel: 'Barbados',
        phone: '+1-246',
        longCode: 'BRB',
    },
    {
        code: 'BY',
        defaultLabel: 'Belarus',
        phone: '+375',
        longCode: 'BLR',
    },
    {
        code: 'BE',
        defaultLabel: 'Belgium',
        phone: '+32',
        longCode: 'BEL',
    },
    {
        code: 'BZ',
        defaultLabel: 'Belize',
        phone: '+501',
        longCode: 'BLZ',
    },
    { code: 'BJ', defaultLabel: 'Benin', phone: '+229', longCode: 'BEN' },
    {
        code: 'BM',
        defaultLabel: 'Bermuda',
        phone: '+1-441',
        longCode: 'BMU',
    },
    {
        code: 'BT',
        defaultLabel: 'Bhutan',
        phone: '+975',
        longCode: 'BTN',
    },
    {
        code: 'BO',
        defaultLabel: 'Bolivia',
        phone: '+591',
        longCode: 'BOL',
    },
    {
        code: 'BA',
        defaultLabel: 'Bosnia and Herzegovina',
        phone: '+387',
        longCode: 'BIH',
    },
    {
        code: 'BW',
        defaultLabel: 'Botswana',
        phone: '+267',
        longCode: 'BWA',
    },
    {
        code: 'BV',
        defaultLabel: 'Bouvet Island',
        phone: '+47',
        longCode: 'BVT',
    },
    { code: 'BR', defaultLabel: 'Brazil', phone: '+55', longCode: 'BRA' },
    {
        code: 'IO',
        defaultLabel: 'British Indian Ocean Territory',
        phone: '+246',
        longCode: 'IOT',
    },
    {
        code: 'VG',
        defaultLabel: 'British Virgin Islands',
        phone: '+1-284',
        longCode: 'VGB',
    },
    {
        code: 'BN',
        defaultLabel: 'Brunei Darussalam',
        phone: '+673',
        longCode: 'BRN',
    },
    {
        code: 'BG',
        defaultLabel: 'Bulgaria',
        phone: '+359',
        longCode: 'BGR',
    },
    {
        code: 'BF',
        defaultLabel: 'Burkina Faso',
        phone: '+226',
        longCode: 'BFA',
    },
    {
        code: 'BI',
        defaultLabel: 'Burundi',
        phone: '+257',
        longCode: 'BDI',
    },
    {
        code: 'KH',
        defaultLabel: 'Cambodia',
        phone: '+855',
        longCode: 'KHM',
    },
    {
        code: 'CM',
        defaultLabel: 'Cameroon',
        phone: '+237',
        longCode: 'CMR',
    },
    { code: 'CA', defaultLabel: 'Canada', phone: '+1', longCode: 'CAN' },
    {
        code: 'CV',
        defaultLabel: 'Cape Verde',
        phone: '+238',
        longCode: 'CPV',
    },
    {
        code: 'KY',
        defaultLabel: 'Cayman Islands',
        phone: '+1-345',
        longCode: 'CYM',
    },
    {
        code: 'CF',
        defaultLabel: 'Central African Republic',
        phone: '+236',
        longCode: 'CAF',
    },
    { code: 'TD', defaultLabel: 'Chad', phone: '+235', longCode: 'TCD' },
    { code: 'CL', defaultLabel: 'Chile', phone: '+56', longCode: 'CHL' },
    { code: 'CN', defaultLabel: 'China', phone: '+86', longCode: 'CHN' },
    {
        code: 'CX',
        defaultLabel: 'Christmas Island',
        phone: '+61',
        longCode: 'CXR',
    },
    {
        code: 'CC',
        defaultLabel: 'Cocos (Keeling) Islands',
        phone: '+61',
        longCode: 'CCK',
    },
    {
        code: 'CO',
        defaultLabel: 'Colombia',
        phone: '+57',
        longCode: 'COL',
    },
    {
        code: 'KM',
        defaultLabel: 'Comoros',
        phone: '+269',
        longCode: 'COM',
    },
    {
        code: 'CD',
        defaultLabel: 'Congo, Democratic Republic of the',
        phone: '+243',
        longCode: 'COD',
    },
    {
        code: 'CG',
        defaultLabel: 'Congo, Republic of the',
        phone: '+242',
        longCode: 'COG',
    },
    {
        code: 'CK',
        defaultLabel: 'Cook Islands',
        phone: '+682',
        longCode: 'COK',
    },
    {
        code: 'CR',
        defaultLabel: 'Costa Rica',
        phone: '+506',
        longCode: 'CRI',
    },
    {
        code: 'CI',
        defaultLabel: "Cote d'Ivoire",
        phone: '+225',
        longCode: 'CIV',
    },
    {
        code: 'HR',
        defaultLabel: 'Croatia',
        phone: '+385',
        longCode: 'HRV',
    },
    { code: 'CU', defaultLabel: 'Cuba', phone: '+53', longCode: 'CUB' },
    {
        code: 'CW',
        defaultLabel: 'Curacao',
        phone: '+599',
        longCode: 'CUW',
    },
    {
        code: 'CY',
        defaultLabel: 'Cyprus',
        phone: '+357',
        longCode: 'CYP',
    },
    {
        code: 'CZ',
        defaultLabel: 'Czech Republic',
        phone: '+420',
        longCode: 'CZE',
    },
    {
        code: 'DK',
        defaultLabel: 'Denmark',
        phone: '+45',
        longCode: 'DNK',
    },
    {
        code: 'DJ',
        defaultLabel: 'Djibouti',
        phone: '+253',
        longCode: 'DJI',
    },
    {
        code: 'DM',
        defaultLabel: 'Dominica',
        phone: '+1-767',
        longCode: 'DMA',
    },
    {
        code: 'DO',
        defaultLabel: 'Dominican Republic',
        phone: '+1-809',
        longCode: 'DOM',
    },
    {
        code: 'EC',
        defaultLabel: 'Ecuador',
        phone: '+593',
        longCode: 'ECU',
    },
    { code: 'EG', defaultLabel: 'Egypt', phone: '+20', longCode: 'EGY' },
    {
        code: 'SV',
        defaultLabel: 'El Salvador',
        phone: '+503',
        longCode: 'SLV',
    },
    {
        code: 'GQ',
        defaultLabel: 'Equatorial Guinea',
        phone: '+240',
        longCode: 'GNQ',
    },
    {
        code: 'ER',
        defaultLabel: 'Eritrea',
        phone: '+291',
        longCode: 'ERI',
    },
    {
        code: 'EE',
        defaultLabel: 'Estonia',
        phone: '+372',
        longCode: 'EST',
    },
    {
        code: 'ET',
        defaultLabel: 'Ethiopia',
        phone: '+251',
        longCode: 'ETH',
    },
    {
        code: 'FK',
        defaultLabel: 'Falkland Islands (Malvinas)',
        phone: '+500',
        longCode: 'FLK',
    },
    {
        code: 'FO',
        defaultLabel: 'Faroe Islands',
        phone: '+298',
        longCode: 'FRO',
    },
    { code: 'FJ', defaultLabel: 'Fiji', phone: '+679', longCode: 'FJI' },
    {
        code: 'FI',
        defaultLabel: 'Finland',
        phone: '+358',
        longCode: 'FIN',
    },
    { code: 'FR', defaultLabel: 'France', phone: '+33', longCode: 'FRA' },
    {
        code: 'GF',
        defaultLabel: 'French Guiana',
        phone: '+594',
        longCode: 'GUF',
    },
    {
        code: 'PF',
        defaultLabel: 'French Polynesia',
        phone: '+689',
        longCode: 'PYF',
    },
    {
        code: 'TF',
        defaultLabel: 'French Southern Territories',
        phone: '+262',
        longCode: 'ATF',
    },
    { code: 'GA', defaultLabel: 'Gabon', phone: '+241', longCode: 'GAB' },
    {
        code: 'GM',
        defaultLabel: 'Gambia',
        phone: '+220',
        longCode: 'GMB',
    },
    {
        code: 'GE',
        defaultLabel: 'Georgia',
        phone: '+995',
        longCode: 'GEO',
    },
    {
        code: 'DE',
        defaultLabel: 'Germany',
        phone: '+49',
        longCode: 'DEU',
    },
    { code: 'GH', defaultLabel: 'Ghana', phone: '+233', longCode: 'GHA' },
    {
        code: 'GI',
        defaultLabel: 'Gibraltar',
        phone: '+350',
        longCode: 'GIB',
    },
    { code: 'GR', defaultLabel: 'Greece', phone: '+30', longCode: 'GRC' },
    {
        code: 'GL',
        defaultLabel: 'Greenland',
        phone: '+299',
        longCode: 'GRL',
    },
    {
        code: 'GD',
        defaultLabel: 'Grenada',
        phone: '+1-473',
        longCode: 'GRD',
    },
    {
        code: 'GP',
        defaultLabel: 'Guadeloupe',
        phone: '+590',
        longCode: 'GLP',
    },
    {
        code: 'GU',
        defaultLabel: 'Guam',
        phone: '+1-671',
        longCode: 'GUM',
    },
    {
        code: 'GT',
        defaultLabel: 'Guatemala',
        phone: '+502',
        longCode: 'GTM',
    },
    {
        code: 'GG',
        defaultLabel: 'Guernsey',
        phone: '+44',
        longCode: 'GGY',
    },
    {
        code: 'GN',
        defaultLabel: 'Guinea',
        phone: '+224',
        longCode: 'GIN',
    },
    {
        code: 'GW',
        defaultLabel: 'Guinea-Bissau',
        phone: '+245',
        longCode: 'GNB',
    },
    {
        code: 'GY',
        defaultLabel: 'Guyana',
        phone: '+592',
        longCode: 'GUY',
    },
    { code: 'HT', defaultLabel: 'Haiti', phone: '+509', longCode: 'HTI' },
    {
        code: 'HM',
        defaultLabel: 'Heard Island and McDonald Islands',
        phone: '+672',
        longCode: 'HMD',
    },
    {
        code: 'VA',
        defaultLabel: 'Holy See (Vatican City State)',
        phone: '+379',
        longCode: 'VAT',
    },
    {
        code: 'HN',
        defaultLabel: 'Honduras',
        phone: '+504',
        longCode: 'HND',
    },
    {
        code: 'HK',
        defaultLabel: 'Hong Kong',
        phone: '+852',
        longCode: 'HKG',
    },
    {
        code: 'HU',
        defaultLabel: 'Hungary',
        phone: '+36',
        longCode: 'HUN',
    },
    {
        code: 'IS',
        defaultLabel: 'Iceland',
        phone: '+354',
        longCode: 'ISL',
    },
    { code: 'IN', defaultLabel: 'India', phone: '+91', longCode: 'IND' },
    {
        code: 'ID',
        defaultLabel: 'Indonesia',
        phone: '+62',
        longCode: 'IDN',
    },
    {
        code: 'IR',
        defaultLabel: 'Iran, Islamic Republic of',
        phone: '+98',
        longCode: 'IRN',
    },
    { code: 'IQ', defaultLabel: 'Iraq', phone: '+964', longCode: 'IRQ' },
    {
        code: 'IE',
        defaultLabel: 'Ireland',
        phone: '+353',
        longCode: 'IRL',
    },
    {
        code: 'IM',
        defaultLabel: 'Isle of Man',
        phone: '+44',
        longCode: 'IMN',
    },
    {
        code: 'IL',
        defaultLabel: 'Israel',
        phone: '+972',
        longCode: 'ISR',
    },
    { code: 'IT', defaultLabel: 'Italy', phone: '+39', longCode: 'ITA' },
    {
        code: 'JM',
        defaultLabel: 'Jamaica',
        phone: '+1-876',
        longCode: 'JAM',
    },
    { code: 'JP', defaultLabel: 'Japan', phone: '+81', longCode: 'JPN' },
    { code: 'JE', defaultLabel: 'Jersey', phone: '+44', longCode: 'JEY' },
    {
        code: 'JO',
        defaultLabel: 'Jordan',
        phone: '+962',
        longCode: 'JOR',
    },
    {
        code: 'KZ',
        defaultLabel: 'Kazakhstan',
        phone: '+7',
        longCode: 'KAZ',
    },
    { code: 'KE', defaultLabel: 'Kenya', phone: '+254', longCode: 'KEN' },
    {
        code: 'KI',
        defaultLabel: 'Kiribati',
        phone: '+686',
        longCode: 'KIR',
    },
    {
        code: 'KP',
        defaultLabel: "Korea, Democratic People's Republic of",
        phone: '+850',
        longCode: 'PRK',
    },
    {
        code: 'KR',
        defaultLabel: 'Korea, Republic of',
        phone: '+82',
        longCode: 'KOR',
    },
    { code: 'XK', defaultLabel: 'Kosovo', phone: '+383', longCode: 'XKX' },
    {
        code: 'KW',
        defaultLabel: 'Kuwait',
        phone: '+965',
        longCode: 'KWT',
    },
    {
        code: 'KG',
        defaultLabel: 'Kyrgyzstan',
        phone: '+996',
        longCode: 'KGZ',
    },
    {
        code: 'LA',
        defaultLabel: "Lao People's Democratic Republic",
        phone: '+856',
        longCode: 'LAO',
    },
    {
        code: 'LV',
        defaultLabel: 'Latvia',
        phone: '+371',
        longCode: 'LVA',
    },
    {
        code: 'LB',
        defaultLabel: 'Lebanon',
        phone: '+961',
        longCode: 'LBN',
    },
    {
        code: 'LS',
        defaultLabel: 'Lesotho',
        phone: '+266',
        longCode: 'LSO',
    },
    {
        code: 'LR',
        defaultLabel: 'Liberia',
        phone: '+231',
        longCode: 'LBR',
    },
    { code: 'LY', defaultLabel: 'Libya', phone: '+218', longCode: 'LBY' },
    {
        code: 'LI',
        defaultLabel: 'Liechtenstein',
        phone: '+423',
        longCode: 'LIE',
    },
    {
        code: 'LT',
        defaultLabel: 'Lithuania',
        phone: '+370',
        longCode: 'LTU',
    },
    {
        code: 'LU',
        defaultLabel: 'Luxembourg',
        phone: '+352',
        longCode: 'LUX',
    },
    { code: 'MO', defaultLabel: 'Macao', phone: '+853', longCode: 'MAC' },
    {
        code: 'MK',
        defaultLabel: 'Macedonia, the Former Yugoslav Republic of',
        phone: '+389',
        longCode: 'MKD',
    },
    {
        code: 'MG',
        defaultLabel: 'Madagascar',
        phone: '+261',
        longCode: 'MDG',
    },
    {
        code: 'MW',
        defaultLabel: 'Malawi',
        phone: '+265',
        longCode: 'MWI',
    },
    {
        code: 'MY',
        defaultLabel: 'Malaysia',
        phone: '+60',
        longCode: 'MYS',
    },
    {
        code: 'MV',
        defaultLabel: 'Maldives',
        phone: '+960',
        longCode: 'MDV',
    },
    { code: 'ML', defaultLabel: 'Mali', phone: '+223', longCode: 'MLI' },
    { code: 'MT', defaultLabel: 'Malta', phone: '+356', longCode: 'MLT' },
    {
        code: 'MH',
        defaultLabel: 'Marshall Islands',
        phone: '+692',
        longCode: 'MHL',
    },
    {
        code: 'MQ',
        defaultLabel: 'Martinique',
        phone: '+596',
        longCode: 'MTQ',
    },
    {
        code: 'MR',
        defaultLabel: 'Mauritania',
        phone: '+222',
        longCode: 'MRT',
    },
    {
        code: 'MU',
        defaultLabel: 'Mauritius',
        phone: '+230',
        longCode: 'MUS',
    },
    {
        code: 'YT',
        defaultLabel: 'Mayotte',
        phone: '+262',
        longCode: 'MYT',
    },
    { code: 'MX', defaultLabel: 'Mexico', phone: '+52', longCode: 'MEX' },
    {
        code: 'FM',
        defaultLabel: 'Micronesia, Federated States of',
        phone: '+691',
        longCode: 'FSM',
    },
    {
        code: 'MD',
        defaultLabel: 'Moldova, Republic of',
        phone: '+373',
        longCode: 'MDA',
    },
    {
        code: 'MC',
        defaultLabel: 'Monaco',
        phone: '+377',
        longCode: 'MCO',
    },
    {
        code: 'MN',
        defaultLabel: 'Mongolia',
        phone: '+976',
        longCode: 'MNG',
    },
    {
        code: 'ME',
        defaultLabel: 'Montenegro',
        phone: '+382',
        longCode: 'MNE',
    },
    {
        code: 'MS',
        defaultLabel: 'Montserrat',
        phone: '+1-664',
        longCode: 'MSR',
    },
    {
        code: 'MA',
        defaultLabel: 'Morocco',
        phone: '+212',
        longCode: 'MAR',
    },
    {
        code: 'MZ',
        defaultLabel: 'Mozambique',
        phone: '+258',
        longCode: 'MOZ',
    },
    {
        code: 'MM',
        defaultLabel: 'Myanmar',
        phone: '+95',
        longCode: 'MMR',
    },
    {
        code: 'NA',
        defaultLabel: 'Namibia',
        phone: '+264',
        longCode: 'NAM',
    },
    { code: 'NR', defaultLabel: 'Nauru', phone: '+674', longCode: 'NRU' },
    { code: 'NP', defaultLabel: 'Nepal', phone: '+977', longCode: 'NPL' },
    {
        code: 'NL',
        defaultLabel: 'Netherlands',
        phone: '+31',
        longCode: 'NLD',
    },
    {
        code: 'NC',
        defaultLabel: 'New Caledonia',
        phone: '+687',
        longCode: 'NCL',
    },
    {
        code: 'NZ',
        defaultLabel: 'New Zealand',
        phone: '+64',
        longCode: 'NZL',
    },
    {
        code: 'NI',
        defaultLabel: 'Nicaragua',
        phone: '+505',
        longCode: 'NIC',
    },
    { code: 'NE', defaultLabel: 'Niger', phone: '+227', longCode: 'NER' },
    {
        code: 'NG',
        defaultLabel: 'Nigeria',
        phone: '+234',
        longCode: 'NGA',
    },
    { code: 'NU', defaultLabel: 'Niue', phone: '+683', longCode: 'NIU' },
    {
        code: 'NF',
        defaultLabel: 'Norfolk Island',
        phone: '+672',
        longCode: 'NFK',
    },
    {
        code: 'MP',
        defaultLabel: 'Northern Mariana Islands',
        phone: '+1-670',
        longCode: 'MNP',
    },
    { code: 'NO', defaultLabel: 'Norway', phone: '+47', longCode: 'NOR' },
    { code: 'OM', defaultLabel: 'Oman', phone: '+968', longCode: 'OMN' },
    {
        code: 'PK',
        defaultLabel: 'Pakistan',
        phone: '+92',
        longCode: 'PAK',
    },
    { code: 'PW', defaultLabel: 'Palau', phone: '+680', longCode: 'PLW' },
    {
        code: 'PS',
        defaultLabel: 'Palestine, State of',
        phone: '+970',
        longCode: 'PSE',
    },
    {
        code: 'PA',
        defaultLabel: 'Panama',
        phone: '+507',
        longCode: 'PAN',
    },
    {
        code: 'PG',
        defaultLabel: 'Papua New Guinea',
        phone: '+675',
        longCode: 'PNG',
    },
    {
        code: 'PY',
        defaultLabel: 'Paraguay',
        phone: '+595',
        longCode: 'PRY',
    },
    { code: 'PE', defaultLabel: 'Peru', phone: '+51', longCode: 'PER' },
    {
        code: 'PH',
        defaultLabel: 'Philippines',
        phone: '+63',
        longCode: 'PHL',
    },
    {
        code: 'PN',
        defaultLabel: 'Pitcairn',
        phone: '+870',
        longCode: 'PCN',
    },
    { code: 'PL', defaultLabel: 'Poland', phone: '+48', longCode: 'POL' },
    {
        code: 'PT',
        defaultLabel: 'Portugal',
        phone: '+351',
        longCode: 'PRT',
    },
    {
        code: 'PR',
        defaultLabel: 'Puerto Rico',
        phone: '+1',
        longCode: 'PRI',
    },
    { code: 'QA', defaultLabel: 'Qatar', phone: '+974', longCode: 'QAT' },
    {
        code: 'RE',
        defaultLabel: 'Reunion',
        phone: '+262',
        longCode: 'REU',
    },
    {
        code: 'RO',
        defaultLabel: 'Romania',
        phone: '+40',
        longCode: 'ROU',
    },
    {
        code: 'RU',
        defaultLabel: 'Russian Federation',
        phone: '+7',
        longCode: 'RUS',
    },
    {
        code: 'RW',
        defaultLabel: 'Rwanda',
        phone: '+250',
        longCode: 'RWA',
    },
    {
        code: 'BL',
        defaultLabel: 'Saint Barthelemy',
        phone: '+590',
        longCode: 'BLM',
    },
    {
        code: 'SH',
        defaultLabel: 'Saint Helena',
        phone: '+290',
        longCode: 'SHN',
    },
    {
        code: 'KN',
        defaultLabel: 'Saint Kitts and Nevis',
        phone: '+1-869',
        longCode: 'KNA',
    },
    {
        code: 'LC',
        defaultLabel: 'Saint Lucia',
        phone: '+1-758',
        longCode: 'LCA',
    },
    {
        code: 'MF',
        defaultLabel: 'Saint Martin (French part)',
        phone: '+590',
        longCode: 'MAF',
    },
    {
        code: 'PM',
        defaultLabel: 'Saint Pierre and Miquelon',
        phone: '+508',
        longCode: 'SPM',
    },
    {
        code: 'VC',
        defaultLabel: 'Saint Vincent and the Grenadines',
        phone: '+1-784',
        longCode: 'VCT',
    },
    { code: 'WS', defaultLabel: 'Samoa', phone: '+685', longCode: 'WSM' },
    {
        code: 'SM',
        defaultLabel: 'San Marino',
        phone: '+378',
        longCode: 'SMR',
    },
    {
        code: 'ST',
        defaultLabel: 'Sao Tome and Principe',
        phone: '+239',
        longCode: 'STP',
    },
    {
        code: 'SA',
        defaultLabel: 'Saudi Arabia',
        phone: '+966',
        longCode: 'SAU',
    },
    {
        code: 'SN',
        defaultLabel: 'Senegal',
        phone: '+221',
        longCode: 'SEN',
    },
    {
        code: 'RS',
        defaultLabel: 'Serbia',
        phone: '+381',
        longCode: 'SRB',
    },
    {
        code: 'SC',
        defaultLabel: 'Seychelles',
        phone: '+248',
        longCode: 'SYC',
    },
    {
        code: 'SL',
        defaultLabel: 'Sierra Leone',
        phone: '+232',
        longCode: 'SLE',
    },
    {
        code: 'SG',
        defaultLabel: 'Singapore',
        phone: '+65',
        longCode: 'SGP',
    },
    {
        code: 'SX',
        defaultLabel: 'Sint Maarten (Dutch part)',
        phone: '+1-721',
        longCode: 'SXM',
    },
    {
        code: 'SK',
        defaultLabel: 'Slovakia',
        phone: '+421',
        longCode: 'SVK',
    },
    {
        code: 'SI',
        defaultLabel: 'Slovenia',
        phone: '+386',
        longCode: 'SVN',
    },
    {
        code: 'SB',
        defaultLabel: 'Solomon Islands',
        phone: '+677',
        longCode: 'SLB',
    },
    {
        code: 'SO',
        defaultLabel: 'Somalia',
        phone: '+252',
        longCode: 'SOM',
    },
    {
        code: 'ZA',
        defaultLabel: 'South Africa',
        phone: '+27',
        longCode: 'ZAF',
    },
    {
        code: 'GS',
        defaultLabel: 'South Georgia and the South Sandwich Islands',
        phone: '+500',
        longCode: 'SGS',
    },
    {
        code: 'SS',
        defaultLabel: 'South Sudan',
        phone: '+211',
        longCode: 'SSD',
    },
    { code: 'ES', defaultLabel: 'Spain', phone: '+34', longCode: 'ESP' },
    {
        code: 'LK',
        defaultLabel: 'Sri Lanka',
        phone: '+94',
        longCode: 'LKA',
    },
    { code: 'SD', defaultLabel: 'Sudan', phone: '+249', longCode: 'SDN' },
    {
        code: 'SR',
        defaultLabel: 'Suriname',
        phone: '+597',
        longCode: 'SUR',
    },
    {
        code: 'SJ',
        defaultLabel: 'Svalbard and Jan Mayen',
        phone: '+47',
        longCode: 'SJM',
    },
    {
        code: 'SZ',
        defaultLabel: 'Swaziland',
        phone: '+268',
        longCode: 'SWZ',
    },
    { code: 'SE', defaultLabel: 'Sweden', phone: '+46', longCode: 'SWE' },
    {
        code: 'CH',
        defaultLabel: 'Switzerland',
        phone: '+41',
        longCode: 'CHE',
    },
    {
        code: 'SY',
        defaultLabel: 'Syrian Arab Republic',
        phone: '+963',
        longCode: 'SYR',
    },
    {
        code: 'TW',
        defaultLabel: 'Taiwan',
        phone: '+886',
        longCode: 'TWN',
    },
    {
        code: 'TJ',
        defaultLabel: 'Tajikistan',
        phone: '+992',
        longCode: 'TJK',
    },
    {
        code: 'TH',
        defaultLabel: 'Thailand',
        phone: '+66',
        longCode: 'THA',
    },
    {
        code: 'TL',
        defaultLabel: 'Timor-Leste',
        phone: '+670',
        longCode: 'TLS',
    },
    { code: 'TG', defaultLabel: 'Togo', phone: '+228', longCode: 'TGO' },
    {
        code: 'TK',
        defaultLabel: 'Tokelau',
        phone: '+690',
        longCode: 'TKL',
    },
    { code: 'TO', defaultLabel: 'Tonga', phone: '+676', longCode: 'TON' },
    {
        code: 'TT',
        defaultLabel: 'Trinidad and Tobago',
        phone: '+1-868',
        longCode: 'TTO',
    },
    {
        code: 'TN',
        defaultLabel: 'Tunisia',
        phone: '+216',
        longCode: 'TUN',
    },
    { code: 'TR', defaultLabel: 'Turkey', phone: '+90', longCode: 'TUR' },
    {
        code: 'TM',
        defaultLabel: 'Turkmenistan',
        phone: '+993',
        longCode: 'TKM',
    },
    {
        code: 'TC',
        defaultLabel: 'Turks and Caicos Islands',
        phone: '+1-649',
        longCode: 'TCA',
    },
    {
        code: 'TV',
        defaultLabel: 'Tuvalu',
        phone: '+688',
        longCode: 'TUV',
    },
    {
        code: 'VI',
        defaultLabel: 'US Virgin Islands',
        phone: '+1-340',
        longCode: 'VIR',
    },
    {
        code: 'UG',
        defaultLabel: 'Uganda',
        phone: '+256',
        longCode: 'UGA',
    },
    {
        code: 'UA',
        defaultLabel: 'Ukraine',
        phone: '+380',
        longCode: 'UKR',
    },
    {
        code: 'AE',
        defaultLabel: 'United Arab Emirates',
        phone: '+971',
        longCode: 'ARE',
    },
    {
        code: 'GB',
        defaultLabel: 'United Kingdom',
        phone: '+44',
        longCode: 'GBR',
    },
    {
        code: 'TZ',
        defaultLabel: 'United Republic of Tanzania',
        phone: '+255',
        longCode: 'TZA',
    },
    {
        code: 'US',
        defaultLabel: 'United States',
        phone: '+1',
        longCode: 'USA',
    },
    {
        code: 'UY',
        defaultLabel: 'Uruguay',
        phone: '+598',
        longCode: 'URY',
    },
    {
        code: 'UZ',
        defaultLabel: 'Uzbekistan',
        phone: '+998',
        longCode: 'UZB',
    },
    {
        code: 'VU',
        defaultLabel: 'Vanuatu',
        phone: '+678',
        longCode: 'VUT',
    },
    {
        code: 'VE',
        defaultLabel: 'Venezuela',
        phone: '+58',
        longCode: 'VEN',
    },
    {
        code: 'VN',
        defaultLabel: 'Vietnam',
        phone: '+84',
        longCode: 'VNM',
    },
    {
        code: 'WF',
        defaultLabel: 'Wallis and Futuna',
        phone: '+681',
        longCode: 'WLF',
    },
    {
        code: 'EH',
        defaultLabel: 'Western Sahara',
        phone: '+212',
        longCode: 'ESH',
    },
    { code: 'YE', defaultLabel: 'Yemen', phone: '+967', longCode: 'YEM' },
    {
        code: 'ZM',
        defaultLabel: 'Zambia',
        phone: '+260',
        longCode: 'ZMB',
    },
    {
        code: 'ZW',
        defaultLabel: 'Zimbabwe',
        phone: '+263',
        longCode: 'ZWE',
    },
];
