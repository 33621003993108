import React from 'react';
import { createGlobalStyle } from '@harver/shared-ui';

export const GlobalStyle: React.ComponentType<any> = createGlobalStyle`
    html,
    body {
        width: 100%;
        height: 100%;
        color: ${({ theme }) => theme.colors.neutral['900']};
        overflow: hidden;
    }

    body {
        background-color: ${({ theme }) => theme.colors.neutral['025']};
    }

    html {
        font-size: 62.5%;
    }

    #root {
        width: 100vw;
        min-width: 320px;
        height: 100%;
    }
` as any;

export const focusOutline = `
    outline-width: 1px;
    outline-style: dashed;
    outline-color: red;
`;

export const srOnly = `
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
`;
