import { logger } from './logger';
import { AxiosRequestConfig, AxiosResponse, AxiosStatic } from 'axios';
import { parseURL } from '@harver/journey-shared';

export const getAPIbaseURL = (): string => {
    const parsedUrl = parseURL(window.location.href);

    const isDevURL = parsedUrl.url.hostname.includes('harver-dev.com');

    // a special case for our ephemeral environments
    if (isDevURL) {
        const parsedSubdomains = parsedUrl.subdomains.join('.').replace('dev-', 'api-');
        return `${parsedUrl.url.protocol}//${parsedSubdomains}.${parsedUrl.domainTld}/api`;
    }

    // a special for development
    if (parsedUrl.isLocalhost || parsedUrl.isIp) {
        return `${parsedUrl.url.protocol}//${parsedUrl.domainTld}:44703/api`;
    }

    // all other cases/environments
    return `${parsedUrl.url.protocol}//api-journey.${parsedUrl.domainTld}/api`;
};

export type RequestMethod = 'GET' | 'POST' | 'PUT';

interface ApiResponseErrorPayload {
    errorCode?: string;
    httpStatusCode: number;
}

export class ApiResponseError extends Error {
    payload?;

    constructor(payload?: ApiResponseErrorPayload, message?: string) {
        super(message);
        this.name = 'ApiResponseError';
        this.payload = payload;

        // we have to set prototype explicitly
        // https://github.com/Microsoft/TypeScript/issues/13965
        Object.setPrototypeOf(this, ApiResponseError.prototype);
    }
}

// TODO:
// - Add return type, tried doing <T> and Promise<T> but got an error on last `return response`.
// - Add error tracking system here so it's centralized.
export const request = async (
    path: string,
    method: RequestMethod = 'GET',
    requestConfig: Partial<RequestInit> = {},
    abortSignal?: AbortController['signal'],
) => {
    const response = await fetch(path, {
        ...requestConfig,
        method,
        signal: abortSignal,
    });

    let parsedResponse;

    if (response.headers.get('content-type')?.includes('json')) {
        try {
            parsedResponse = await response.json();
        } catch (e) {
            logger.error('Failed to parse network response JSON', e, {
                response: {
                    status: response.status,
                    payload: await response.text(),
                },
            });

            throw e;
        }
    }

    const body = parsedResponse !== undefined ? parsedResponse : response.body;

    if (!response.ok) {
        // TODO:
        // We shouldn't assume that `request` is only used for our API calls.
        throw new ApiResponseError({ ...body, httpStatusCode: response.status }, 'Network response is not OK');
    }

    return body;
};

export const requestWithAxios = async (
    path: string,
    method: RequestMethod = 'GET',
    requestConfig: Partial<AxiosRequestConfig> = {},
) => {
    const axios: AxiosStatic = (await import('axios')).default;
    const response: AxiosResponse = await axios(path, {
        ...requestConfig,
        method,
    }).catch((error) => {
        throw new ApiResponseError(
            { ...response.data, httpStatusCode: response.status, error },
            'Network response is not OK',
        );
    });

    return response.data;
};

export const handleApiRequestError = (error: Error, path: string, message: string) => {
    if (error instanceof ApiResponseError) {
        if (error.payload.httpStatusCode >= 500) {
            logger.error(message, error);
        } else {
            logger.info(message, {
                path: `${getAPIbaseURL()}${path}`,
                payload: error.payload,
            });
        }
    }

    throw error;
};

export const apiGet = <T>(path: string): Promise<T> =>
    request(`${getAPIbaseURL()}${path}`, 'GET', {
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    }).catch((error) => {
        return handleApiRequestError(error, path, 'Error while sending a GET request');
    });

export const apiPost = <T>(path: string, payload: object): Promise<T> =>
    request(`${getAPIbaseURL()}${path}`, 'POST', {
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    }).catch((error) => {
        return handleApiRequestError(error, path, 'Error while sending a POST request');
    });
