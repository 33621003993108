import { logger } from '../../utils/logger';

type ScriptArgs = { id: string; src: string };
type LoadScriptResult = 'loaded' | 'already-loaded' | 'error';

const hasScriptBeenAlreadyLoaded = ({ id, src }: ScriptArgs) => {
    const element = document.getElementById(id);
    return (
        element != null &&
        element.tagName.toUpperCase() === 'SCRIPT' &&
        element.getAttribute('type') === 'text/javascript' &&
        element.getAttribute('src') === src
    );
};

export const loadScript = ({ id, src }: ScriptArgs): Promise<LoadScriptResult> => {
    logger.debug(`Downloading ${id}`);

    if (hasScriptBeenAlreadyLoaded({ id, src })) {
        return Promise.resolve('already-loaded');
    }

    return new Promise((resolve) => {
        const element = document.createElement('script');
        Object.assign(element, { id, src, async: true, type: 'text/javascript' });

        element.addEventListener('load', () => {
            logger.debug(`${id} downloaded and loaded`);
            resolve('loaded');
        });

        element.addEventListener('error', (/* No error information is available :'( */) => {
            const err = Error(`${src} load error detected`);
            logger.error(`${id} load error`, err);
            resolve('error');
        });

        if (!hasScriptBeenAlreadyLoaded({ id, src })) {
            document.body.appendChild(element);
        }
    });
};
