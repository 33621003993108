"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PERSONALITY_PRINT_OPTIONS = exports.PersonalityPrintVariant = void 0;
// eslint-disable-next-line no-shadow
var PersonalityPrintVariant;
(function (PersonalityPrintVariant) {
    PersonalityPrintVariant["Short"] = "short";
    PersonalityPrintVariant["Demo"] = "demo";
    PersonalityPrintVariant["Professional"] = "professional";
})(PersonalityPrintVariant = exports.PersonalityPrintVariant || (exports.PersonalityPrintVariant = {}));
exports.PERSONALITY_PRINT_OPTIONS = [1, 2, 3, 4, 5, 6];
