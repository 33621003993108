"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatProficiencyAssessmentChatStatus = void 0;
var ChatProficiencyAssessmentChatStatus;
(function (ChatProficiencyAssessmentChatStatus) {
    ChatProficiencyAssessmentChatStatus[ChatProficiencyAssessmentChatStatus["Started"] = 0] = "Started";
    ChatProficiencyAssessmentChatStatus[ChatProficiencyAssessmentChatStatus["Delayed"] = 1] = "Delayed";
    ChatProficiencyAssessmentChatStatus[ChatProficiencyAssessmentChatStatus["NotVisible"] = 2] = "NotVisible";
    ChatProficiencyAssessmentChatStatus[ChatProficiencyAssessmentChatStatus["CustomerTyping"] = 3] = "CustomerTyping";
    ChatProficiencyAssessmentChatStatus[ChatProficiencyAssessmentChatStatus["CustomerTyped"] = 4] = "CustomerTyped";
    ChatProficiencyAssessmentChatStatus[ChatProficiencyAssessmentChatStatus["CandidateAnswered"] = 5] = "CandidateAnswered";
    ChatProficiencyAssessmentChatStatus[ChatProficiencyAssessmentChatStatus["Finished"] = 6] = "Finished";
})(ChatProficiencyAssessmentChatStatus = exports.ChatProficiencyAssessmentChatStatus || (exports.ChatProficiencyAssessmentChatStatus = {}));
