import type {
    ChatbotHookDependencies,
    ErrorPageChatbotDependencies,
    JourneyPageChatbotDependencies,
    LandingPageChatbotDependencies,
    UseChatbotOutput,
} from './types';

import { useChatbot } from './useChatbot';
import { getJourneyMetadata } from './helpers';

export type {
    UseChatbotOutput,
    ChatbotHookDependencies,
    ErrorPageChatbotDependencies,
    JourneyPageChatbotDependencies,
    LandingPageChatbotDependencies,
};

export { getJourneyMetadata };

export default useChatbot;
