"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customParamRules = exports.maxLengthOfQueryEmailParam = exports.maxLengthOfQueryParam = exports.maxNumbersOfQueryParam = exports.ForbiddenQueryParams = void 0;
// eslint-disable-next-line no-shadow
var ForbiddenQueryParams;
(function (ForbiddenQueryParams) {
    ForbiddenQueryParams["email"] = "email";
})(ForbiddenQueryParams = exports.ForbiddenQueryParams || (exports.ForbiddenQueryParams = {}));
exports.maxNumbersOfQueryParam = 30;
exports.maxLengthOfQueryParam = 30;
exports.maxLengthOfQueryEmailParam = 90;
exports.customParamRules = { [ForbiddenQueryParams.email]: exports.maxLengthOfQueryEmailParam };
