import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress } from '@harver/shared-ui';

type Props = {
    testId?: string;
    skipShowTimeout?: boolean;
};

// eslint-disable-next-line react/display-name
export const LoadingSpinner: React.FC<Props> = React.memo(
    ({ testId, skipShowTimeout = false }) => {
        const [isShown, setIsShown] = useState(skipShowTimeout);
        const ref = useRef(true);

        useEffect(() => {
            if (!isShown) {
                setTimeout(() => {
                    if (ref.current) {
                        setIsShown(true);
                    }
                }, 300);
            }
            return () => {
                ref.current = false;
            };
        }, []);

        return (
            <div
                css={{
                    position: 'absolute' as const,
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                role="progressbar"
                aria-label="Loading"
                data-testid={testId}
            >
                {isShown && <CircularProgress color="primary" />}
            </div>
        );
    },
    () => true,
);
