import { useReducer, useEffect, useMemo } from 'react';
import { ApiResponseError, request, RequestMethod } from '../utils/httpClient';
import { logger } from '../utils/logger';

enum ActionType {
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

type State<T> = {
    data: T | undefined;
    error: ApiResponseError | undefined;
    loading: boolean;
};

type Action<T> = {
    type: ActionType;
    payload?: T | ApiResponseError;
};

// unfortunately typesript doesn't work well with switch-case, thats why
// explicit typings are used
const createFetchReducer = <T>() => (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
        case ActionType.LOADING:
            return {
                ...state,
                loading: true,
                error: undefined,
                data: undefined,
            };
        case ActionType.SUCCESS:
            return {
                ...state,
                data: action.payload as T,
                error: undefined,
                loading: false,
            };
        case ActionType.ERROR:
            return {
                ...state,
                data: undefined,
                error: action.payload as Error,
                loading: false,
            };
        default:
            return state;
    }
};

export type UseFetchParams = {
    url: RequestInfo;
    defaultOptions?: RequestInit & { search?: Record<string, string> };
    mount?: boolean;
};

const useFetch = <Data = unknown>({
    url,
    defaultOptions,
    mount = true,
}: UseFetchParams): [
    State<Data>['data'],
    State<Data>['loading'],
    State<Data>['error'],
    (options?: UseFetchParams['defaultOptions']) => Promise<void>,
] => {
    const fetchReducer = createFetchReducer<Data>();
    const [state, dispatch] = useReducer(fetchReducer, {
        data: undefined,
        error: undefined,
        loading: mount,
    });

    const [fetch, _abortFetch] = useMemo(() => {
        let abortController: AbortController;
        const internalFetch = async (options = defaultOptions): Promise<void> => {
            abortController = new AbortController();
            dispatch({ type: ActionType.LOADING });

            let data: Data;
            const method: RequestMethod =
                (options?.method as RequestMethod) || (defaultOptions?.method as RequestMethod) || 'GET';

            try {
                const [rawUrl, ...restQs] = url.toString().split('?');
                // only the first question mark in an url has any significance, so join the rest
                // back together using a ? character
                // see: https://www.rfc-editor.org/rfc/rfc3986#section-3.3
                const reconstructedQs = restQs.join('?');
                const parsedUrl = new URL(rawUrl);
                // options.search string takes precedence over the given one during hook initialization
                // then when mount is set to false and call an endpoint multiple times, then you can change
                // qs to your liking but keeping original url, ex: calling api vacancy endpoint with
                // different language settings
                parsedUrl.search = new URLSearchParams(options?.search ?? reconstructedQs ?? {}).toString();

                data = await request(
                    parsedUrl.toString(),
                    method,
                    {
                        ...defaultOptions,
                        ...options,
                    },
                    abortController.signal,
                );
            } catch (error) {
                if (error.name === 'AbortError' || abortController.signal.aborted) {
                    logger.info('Fetch aborted', { error, url: url.toString(), search: options?.search ?? {} });
                    return;
                }

                return dispatch({ type: ActionType.ERROR, payload: error });
            }

            if (!abortController.signal.aborted) {
                return dispatch({ type: ActionType.SUCCESS, payload: data });
            }

            logger.warn('Fetch successful but aborted', { url: url.toString(), search: options?.search ?? {} });
        };

        const internalAbortFetch = () => {
            if (abortController && !abortController.signal.aborted) {
                abortController.abort();
            }
        };
        return [internalFetch, internalAbortFetch];
    }, [defaultOptions, url]);

    useEffect(() => {
        if (mount) {
            fetch();
        }

        return () => {
            _abortFetch();
        };
    }, []);

    return [state.data, state.loading, state.error, fetch];
};

export default useFetch;
